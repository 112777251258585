// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var I18N$BsConsole = require("./I18N.js");
var Task$BsConsole = require("./task.js");
var Error$BsConsole = require("./error.js");
var Column$BsConsole = require("./utilities/Column.js");
var Footer$BsConsole = require("./Footer.js");
var ApiRecover$BsConsole = require("./apiRecover.js");
var UDashEntry$BsConsole = require("./UDashEntry.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var ViewContainer$BsConsole = require("./ViewContainer.js");

var grid = Css.style(/* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* [] */0
      ]
    ]);

var button = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var resetButton = Css.style(/* :: */[
      Css.marginBottom(Css.px(16)),
      /* [] */0
    ]);

var gridPadding = Css.style(/* :: */[
      Css.padding(Css.px(12)),
      /* [] */0
    ]);

var loadingContainer = Css.style(/* :: */[
      Css.height(Css.px(320)),
      /* [] */0
    ]);

var paper = Css.style(/* :: */[
      Css.padding(Css.px(16)),
      /* [] */0
    ]);

var card = Css.style(/* :: */[
      Css.width(Css.px(450)),
      /* [] */0
    ]);

var cardContent = Css.style(/* :: */[
      Css.important(Css.padding(Css.px(32))),
      /* [] */0
    ]);

var logoContainer = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var heading = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* :: */[
        Css.alignSelf(/* center */98248149),
        /* [] */0
      ]
    ]);

var termsOfService = Css.style(/* :: */[
      Css.marginBottom(Css.px(20)),
      /* [] */0
    ]);

var input = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* [] */0
    ]);

var statusContainer = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* [] */0
    ]);

var statusHeader = Css.style(/* :: */[
      Css.marginBottom(Css.px(16)),
      /* :: */[
        Css.alignSelf(/* center */98248149),
        /* [] */0
      ]
    ]);

var subtitle = Css.style(/* :: */[
      Css.alignSelf(/* center */98248149),
      /* [] */0
    ]);

var Styles = {
  grid: grid,
  button: button,
  resetButton: resetButton,
  gridPadding: gridPadding,
  loadingContainer: loadingContainer,
  paper: paper,
  card: card,
  cardContent: cardContent,
  logoContainer: logoContainer,
  logo: logo,
  heading: heading,
  termsOfService: termsOfService,
  input: input,
  statusContainer: statusContainer,
  statusHeader: statusHeader,
  subtitle: subtitle
};

var component = ReasonReact.reducerComponent("Recover");

function make(handleTask, handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var msg = self.state.remote;
              var tmp;
              tmp = typeof msg === "number" ? (
                  msg === /* NotAsked */0 ? ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                              ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading2 */1, undefined, heading, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Reset your password")])),
                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Input.Jsx2.make, [
                                        undefined,
                                        input,
                                        /* `String */[
                                          -976970511,
                                          self.state.username
                                        ],
                                        (function ($$event) {
                                            var username = $$event.target.value;
                                            return Curry._1(self.send, /* HandleUsername */Block.__(2, [username]));
                                          }),
                                        I18N$BsConsole.get(undefined, "Username"),
                                        true,
                                        undefined,
                                        undefined,
                                        "text",
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        []
                                      ])),
                              UDashEntry$BsConsole.isUnityByHostname(undefined) ? ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Body1 */8, undefined, termsOfService, undefined, undefined, undefined, [
                                          I18N$BsConsole.show(undefined, "By using the Backtrace Service as provided by Sauce Labs Inc, you agree to the "),
                                          React.createElement("a", {
                                                href: "https://saucelabs.com/doc/terms-of-service",
                                                rel: "noopener noreferrer",
                                                target: "_blank"
                                              }, I18N$BsConsole.show(undefined, "Sauce Labs Terms of Service."))
                                        ])) : null,
                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                        /* Medium */861718677,
                                        resetButton,
                                        (function (_event) {
                                            return Curry._1(self.send, /* Request */0);
                                          }),
                                        undefined,
                                        undefined,
                                        /* Raised */-387916264,
                                        /* Primary */-791844958,
                                        "submit",
                                        undefined,
                                        self.state.username === "",
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [I18N$BsConsole.show(undefined, "Reset password")]
                                      ])),
                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                        /* Medium */861718677,
                                        button,
                                        (function (_event) {
                                            return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                            referrer: undefined,
                                                            token: undefined,
                                                            redirect: undefined,
                                                            method: undefined
                                                          }]));
                                          }),
                                        undefined,
                                        undefined,
                                        /* Flat */781662169,
                                        /* Primary */-791844958,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [I18N$BsConsole.show(undefined, "Back to log in")]
                                      ]))
                            ])) : ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, loadingContainer, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, "primary", undefined, undefined, []))]))
                ) : (
                  msg.tag ? ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                              ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, statusContainer, [
                                        ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading2 */1, undefined, statusHeader, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Error!")])),
                                        ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, subtitle, undefined, undefined, undefined, [I18N$BsConsole.showSkip(msg[0])]))
                                      ])),
                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                        /* Medium */861718677,
                                        button,
                                        (function (_event) {
                                            return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                            referrer: undefined,
                                                            token: undefined,
                                                            redirect: undefined,
                                                            method: undefined
                                                          }]));
                                          }),
                                        undefined,
                                        undefined,
                                        /* Flat */781662169,
                                        /* Primary */-791844958,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [I18N$BsConsole.show(undefined, "Back to log in")]
                                      ]))
                            ])) : ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                              ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, statusContainer, [
                                        ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading2 */1, undefined, statusHeader, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Success!")])),
                                        ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, subtitle, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Please check your email for further instructions")]))
                                      ])),
                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                        /* Medium */861718677,
                                        button,
                                        (function (_event) {
                                            return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                            referrer: undefined,
                                                            token: undefined,
                                                            redirect: undefined,
                                                            method: undefined
                                                          }]));
                                          }),
                                        undefined,
                                        undefined,
                                        /* Raised */-387916264,
                                        /* Primary */-791844958,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [I18N$BsConsole.show(undefined, "Back to log in")]
                                      ]))
                            ]))
                );
              return ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(Css.merge(/* :: */[
                                  grid,
                                  /* :: */[
                                    "login-container",
                                    /* [] */0
                                  ]
                                ]), [
                              ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, card, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, cardContent, [
                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, logoContainer, [React.createElement("img", {
                                                                  className: logo,
                                                                  src: "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg"
                                                                })])),
                                                  tmp
                                                ]))])),
                              ReasonReact.element(undefined, undefined, Footer$BsConsole.make([]))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      username: "",
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            username: state.username,
                            remote: /* Loading */1
                          },
                          (function (self) {
                              return Curry._2(handleTask, (function (json) {
                                            var remoteData = ApiRecover$BsConsole.decode(TaskCallback$BsConsole.getJson(json));
                                            if (remoteData !== undefined) {
                                              return Curry._1(self.send, /* Success */Block.__(0, [remoteData]));
                                            }
                                            var error = Error$BsConsole.HTTPD.decode(TaskCallback$BsConsole.getJson(json));
                                            if (error !== undefined) {
                                              return Curry._1(self.send, /* Failure */Block.__(1, [error.message]));
                                            } else {
                                              return Curry._1(self.send, /* Failure */Block.__(1, [I18N$BsConsole.get(undefined, "generic error message")]));
                                            }
                                          }), Task$BsConsole.makeTask(/* Recover */Block.__(5, [state.username])));
                            })
                        ]);
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    return /* Update */Block.__(0, [{
                                username: state.username,
                                remote: /* Success */Block.__(0, [action[0]])
                              }]);
                case /* Failure */1 :
                    return /* Update */Block.__(0, [{
                                username: state.username,
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                case /* HandleUsername */2 :
                    return /* Update */Block.__(0, [{
                                username: action[0],
                                remote: state.remote
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* grid Not a pure module */
